import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { StateService, StateParams } from '@uirouter/core';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './challenge-editor.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { BrandsService } from '~/source/management/brand/services/brands';
import { Editable } from '~/source/common/utilities/editable';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';
import {
  Challenge,
  CHALLENGE_TYPES,
  ChallengeAccountBalanceConfig,
  ChallengeBalanceGroup,
  ChallengeBalanceGroupsCreate,
  ChallengeGroup,
  ChallengeGroupCreate,
  ChallengePhase,
  ChallengePhasePayload,
  ChallengePhaseType,
  ChallengeTypes,
} from '@proftit/crm.api.models.entities/src';
import {
  PageTypes,
  generatePhaseModel,
  parseChallengesResponse,
  parsePhases,
  prepareBalanceGroups,
  prepareChallengePhases,
  prepareChallengesContainers,
  sanitizeChallengeBalanceGroup,
  PageType,
} from '~/source/management/challenges/components/challenge-editor/challenges-editor-uils';

// @ts-ignore
import styles from './challenge-editor.component.scss';
import { Brand, Currency } from '@proftit/crm.api.models.entities';
import BrandChallengesGroupsService from '~/source/management/challenges/services/brand-challenges-groups.service';
import ChallengeGroupService from '~/source/management/challenges/services/challenge-group.service';
export class ChallengeEditorController {
  styles = styles;
  challenge: any[] = [];
  pageTypes = PageTypes;
  CHALLENGE_TYPES = CHALLENGE_TYPES;
  challengeSettings = {
    phases: [],
  };
  brandCurrencies: any[] = [];
  lifecycles = observeComponentLifecycles(this);
  blockUiRef = generateBlockuiId();
  editManager = new Editable<any>();
  pageType$ = this.streamPageType();
  createChallenges = new rx.Subject<void>();
  updateChallenges = new rx.Subject<void>();
  phaseUpdate = new rx.Subject<void>();
  brandCurrencies$: rx.Observable<Currency[]> = this.streamBrandCurrencies();
  challengesGroup$ = this.streamChallengesGroup();
  currentChallengeGroup = new rx.Subject<ChallengeGroup>();
  challengeGroupName: string;
  challengeType: ChallengeTypes;
  challengeAccountBalanceConfig$: rx.Observable<
    ChallengeAccountBalanceConfig[][]
  > = this.streamChallengeAccountBalanceConfig();
  isValid$ = this.streamIsValid();

  phasesConfig = [];

  /* @ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $state: StateService,
    readonly $stateParams: StateParams,
    readonly brandChallengesGroupsService: BrandChallengesGroupsService,
    readonly challengeGroupService: ChallengeGroupService,
  ) {
    useStreams(
      [
        this.editManager.initiator$,
        this.pageType$,
        this.brandCurrencies$,
        this.challengesGroup$,
        this.challengeAccountBalanceConfig$,
        this.isValid$,
        this.streamOnCancel(),
        this.streamOnSave(),
        this.streamCreateChallenges(),
        this.streamUpdateChallenges(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  /**
   * Streams a list of brands from the service.
   * This method initializes a stream that first waits for the component's shared initialization lifecycle event.
   * Once the initialization event is observed, it performs a switchMap operation to fetch the list of brands
   * using the `brandsService`. The fetched list is then processed to include only the necessary brand details
   * (in this case, adding a `label` property to each brand object). If an error occurs during fetching,
   * the stream will emit a NEVER observable, effectively stopping the stream without terminating it with an error.
   * The resulting stream of brand objects is then shared with any subscriber, replaying the last emitted value
   * to new subscribers to ensure consistency across the application.
   *
   * @returns {Observable<Brand[]>} An observable stream of brand objects with added `label` properties.
   */

  streamBrandCurrencies() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => rx.obs.from(this.fetchBrandCurrencies())),
      rx.map((currencies) => {
        return currencies.map((c: any) => {
          return { ...c.currency };
        });
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Handles the stream for the cancel action.
   * This method sets up a stream that listens for the cancel edit action. When the action is triggered,
   * it navigates the application back to the list view. The stream is configured to replay the last emitted
   * value for any new subscribers, ensuring that the action is consistently handled across the application.
   *
   * @returns {Observable<void>} An observable that performs the navigation action when the cancel edit action is triggered.
   */
  streamOnCancel() {
    return rx.pipe(
      () => this.editManager.cancelEditAction,
      rx.tap(() => {
        this.$state.go('^.list');
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Handles the stream for the save action.
   * This method sets up a stream that listens for the save action. When the action is triggered,
   * it navigates the application back to the list view. Similar to `streamOnCancel`, this stream is also
   * configured to replay the last emitted value for any new subscribers, ensuring that the action is
   * consistently handled across the application.
   *
   * @returns {Observable<void>} An observable that performs the navigation action when the save action is triggered.
   */
  streamOnSave() {
    return rx.pipe(
      () => this.editManager.saveAction,
      rx.tap(() => {
        this.$state.go('^.list');
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Determines if the current action is to create a new challenge.
   * This method sets up a stream that checks if the `challengeId` parameter is present in the state parameters.
   * If `challengeId` is null or undefined, it implies that the action is to create a new challenge. This stream
   * is useful for toggling between create and edit modes in the challenge editor component. The stream is configured
   * to replay the last emitted value for any new subscribers, ensuring that the create/edit mode is consistently
   * determined across the application.
   *
   * @returns {Observable<boolean>} An observable that emits `true` if the action is to create a new challenge, and `false` otherwise.
   */
  streamPageType(): rx.Observable<PageType> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        let pageType: PageType = 'create';
        for (let t in this.pageTypes) {
          if (this.$state.current.url.includes(this.pageTypes[t])) {
            pageType = this.pageTypes[t];
            break;
          }
        }

        return pageType;
      }),
      rx.tap((pageType) => {
        if (pageType === this.pageTypes.CREATE) {
          this.challengeType = this.$stateParams.challengeType;
          if (this.challengeType === this.CHALLENGE_TYPES.PROP) {
            this.challengeSettings.phases.push(generatePhaseModel('funded'));
          } else {
            this.challengeSettings.phases.push(generatePhaseModel('regular'));
          }
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamChallengesGroup(): rx.Observable<Challenge[]> {
    return rx.pipe(
      () => rx.obs.merge(this.pageType$),
      rx.switchMap((pageType) => {
        if (pageType === this.pageTypes.CREATE) return rx.obs.of([]);

        return rx.obs
          .from(
            this.fetchChallengeGroup(this.$stateParams.challengeId, pageType),
          )
          .pipe(rx.catchError(() => []));
      }),
      rx.map((group: ChallengeGroup) => {
        this.challengeGroupName = group.name;
        this.challengeType = group.type
          ? group.type
          : this.$stateParams.challengeType;
        this.currentChallengeGroup.next(group);
        return group.challenges;
      }),
      rx.withLatestFrom(this.pageType$),
      rx.map(([challenges, pageType]) => {
        if (pageType === this.pageTypes.COPY) {
          this.challengeGroupName = 'Copy of ' + this.challengeGroupName;
        }
        return challenges;
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the configuration for challenge account balance based on the current state of the application.
   * This function combines multiple observables to determine the current state (creation or viewing of a challenge),
   * the available brand currencies, and the current challenge data. It then maps these states to a configuration
   * for account balances. If in creation mode, it generates a default configuration for each currency. Otherwise,
   * it returns the existing challenge configuration.
   *
   * The stream is set to replay the last emitted value for any new subscribers, ensuring consistency across the application.
   *
   * @returns {Observable<any[]>} An observable that emits the current account balance configuration.
   */
  streamChallengeAccountBalanceConfig() {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.pageType$,
          this.brandCurrencies$,
          this.challengesGroup$,
        ),
      rx.withLatestFrom(
        this.pageType$,
        this.brandCurrencies$,
        this.challengesGroup$,
      ),
      rx.map(([_, pageType, currencies, challengesGroup]) => {
        if (pageType === this.pageTypes.CREATE) {
          //new challenge
          let acc = currencies.map((c: any) => {
            return {
              currency: c,
              accountBalance: null,
              entryFee: null,
            };
          });
          return [acc];
        }

        if (challengesGroup.length === 0) return [];
        this.challengeSettings.phases = parsePhases(
          challengesGroup[0].phases as ChallengePhasePayload[],
        );
        return parseChallengesResponse(challengesGroup, pageType);
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the validity status of the current challenge configuration.
   * This method sets up a stream that listens for changes in the challenge group,
   * challenge account balance configuration, and phase updates. It then checks
   * the validity of the current challenge configuration based on the presence of
   * required data in the challenge group, balance groups, and phases.
   * challenge configuration is valid, and `false` otherwise.
   */
  streamIsValid(): rx.Observable<boolean> {
    return rx.pipe(
      () =>
        rx.obs.merge(
          this.challengesGroup$,
          this.challengeAccountBalanceConfig$,
          this.phaseUpdate,
        ),
      rx.withLatestFrom(
        this.challengesGroup$,
        this.challengeAccountBalanceConfig$,
      ),
      rx.map(([_, challengesGroup, balanceGroups]) => {
        let isValid = true;
        if (balanceGroups.length === 0) isValid = false;
        balanceGroups.forEach((group: ChallengeAccountBalanceConfig[]) => {
          if (
            group.some(
              (g) =>
                !g.accountBalance ||
                (this.challengeType === CHALLENGE_TYPES.PROP && !g.entryFee),
            )
          ) {
            isValid = false;
          }
        });
        if (this.challengeSettings.phases.length === 0) isValid = false;
        this.challengeSettings.phases.forEach((phase) => {
          if (!phase.isValid) {
            isValid = false;
          }
        });
        return isValid;
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * todo implement load and create new logic
   */
  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  addPhase() {
    this.challengeSettings.phases.push(generatePhaseModel('regular'));
    this.challengeSettings.phases.sort((a, b) => {
      return a.isFunded ? 1 : -1;
    });
  }

  deletePhase(phaseIndex: number) {
    this.challengeSettings.phases.splice(phaseIndex, 1);
  }

  /**
   * Fetches the available currencies for a specific brand.
   *
   * This method calls the `brandsService` to access the `getCurrenciesResource` function, passing the current brand ID
   * from the state parameters. It then expands the currency details for each currency associated with the brand,
   * and fetches the list of currencies. The result is processed to return a plain JavaScript object representation
   * of the currencies.
   * */
  fetchBrandCurrencies() {
    return this.brandsService()
      .getCurrenciesResource(this.$stateParams.brandId)
      .expand('currency')
      .getListWithQuery()
      .then((currencies) => {
        return currencies.plain();
      });
  }

  /**
   * Simulates fetching challenge data.
   * This placeholder function currently returns a resolved promise with an empty array,
   * indicating no challenges are fetched. It's designed to be replaced with actual data fetching logic.
   * todo implement actual data fetching logic
   *
   *
   * @returns {Promise<any[]>} A promise that resolves to an array of challenge data.
   */
  /**
   * Fetches challenge data.
   *
   * This method simulates fetching challenge data by immediately resolving a promise with an empty array.
   * It serves as a placeholder and should be replaced with actual logic to fetch challenge data from a data source.
   * The method currently indicates that no challenges are fetched.
   */
  async fetchChallengeGroup(challengeGroupId: number, pageType: PageType) {
    const data = await this.brandChallengesGroupsService.getChallengesGroupById(
      this.$stateParams.brandId,
      challengeGroupId,
    );
    data.plain();

    let group = data.plain() as ChallengeGroup;

    if (pageType === this.pageTypes.COPY) {
      group = this.cleanChallengesForCopy(group);
    }

    return group;
  }

  cleanChallengesForCopy(challengeGroup: ChallengeGroup): ChallengeGroup {
    delete challengeGroup.id;

    challengeGroup.challenges = challengeGroup.challenges.map((challenge) => {
      delete challenge.id;
      delete challenge.challengeGroupId;
      return challenge;
    });

    return challengeGroup;
  }

  streamCreateChallenges() {
    return rx.pipe(
      () => rx.obs.merge(this.createChallenges),
      rx.switchMap(() => {
        return rx.obs
          .from(this.createBrandChallengeGroup())
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.tap((group) => {
        this.currentChallengeGroup.next(group);
      }),
      rx.withLatestFrom(this.challengeAccountBalanceConfig$),
      rx.switchMap(async ([group, balanceGroups]) => {
        return await prepareBalanceGroups(
          this.createChallengeBalanceGroups.bind(this),
          group,
          balanceGroups,
        );
      }),
      rx.withLatestFrom(this.currentChallengeGroup),
      rx.tap(([group, currentChallengeGroup]) => {
        const challenges = prepareChallengesContainers(
          [],
          group,
          this.challengeType,
        ).map((ch) => {
          return {
            ...ch,
            isActive: false,
          };
        });

        const challengesData = prepareChallengePhases(
          challenges,
          this.challengeSettings.phases,
        );

        this.createChallengesAction(currentChallengeGroup.id, challengesData);

        return true;
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamUpdateChallenges() {
    return rx.pipe(
      () => rx.obs.merge(this.updateChallenges),
      rx.withLatestFrom(
        this.currentChallengeGroup,
        this.challengeAccountBalanceConfig$,
      ),
      rx.switchMap(async ([_, group, balanceGroups]) => {
        //update group name could be changed
        await this.updateChallengeGroup(this.$stateParams.brandId, {
          ...group,
          name: this.challengeGroupName,
        });

        //update balance groups
        return prepareBalanceGroups(
          this.createChallengeBalanceGroups.bind(this),
          group,
          balanceGroups,
        );
      }),
      rx.withLatestFrom(this.currentChallengeGroup),
      rx.switchMap(([balanceGroups, currentChallengeGroup]) => {
        const challenges = prepareChallengesContainers(
          currentChallengeGroup.challenges,
          balanceGroups,
          this.challengeType,
        ).map((ch) => {
          return {
            ...ch,
            isActive: false,
          };
        });

        const challengesData = prepareChallengePhases(
          challenges,
          this.challengeSettings.phases,
        );

        return rx.obs.from(
          this.challengeGroupService.updateChallengeMultiple(
            currentChallengeGroup.id,
            challengesData,
          ),
        );
      }),

      shareReplayRefOne(),
    )(null);
  }

  async createBrandChallengeGroup(): Promise<ChallengeGroup> {
    const brandId = this.$stateParams.brandId;
    const name = this.challengeGroupName || 'New Challenge';
    const data: ChallengeGroupCreate = {
      name,
      description: '',
      isActive: false,
      type: this.challengeType,
    };

    const group = await this.brandChallengesGroupsService.createChallengeGroup(
      brandId,
      data,
    );
    return group.plain() as ChallengeGroup;
  }

  async createChallengeBalanceGroup(
    brandId: number,
    data: ChallengeBalanceGroupsCreate,
  ) {
    let group = await this.challengeGroupService.createBalanceGroup(
      brandId,
      data,
    );
    return group.plain() as ChallengeBalanceGroup;
  }

  async createChallengeBalanceGroups(
    challengeGroupId: number,
    data: ChallengeBalanceGroupsCreate[],
  ) {
    return await Promise.all(
      data.map((group) => {
        return this.createChallengeBalanceGroup(challengeGroupId, group);
      }),
    );
  }

  async updateChallengeGroup(brandId: number, challengeGroup: ChallengeGroup) {
    return await this.brandChallengesGroupsService.updateChallengeGroup(
      brandId,
      challengeGroup.id,
      challengeGroup,
    );
  }

  createChallengesAction(
    challengeGroupId: number,
    challengesData: Challenge[],
  ) {
    this.challengeGroupService
      .updateChallengeMultiple(challengeGroupId, challengesData)
      .then((resp) => {
        this.$state.go('^.update', {
          challengeId: challengeGroupId,
          brandId: this.$stateParams.brandId,
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  }
}

export const ChallengeEditorComponent = {
  template,
  controller: ChallengeEditorController,
};
