import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { StateService, StateParams } from '@uirouter/core';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';

import template from './challenge-preview-popup.html';
// @ts-ignore
import styles from './challenge-preview-popup.scss';
import {
  Challenge,
  ChallengeAccountBalanceConfig,
  ChallengeGroup,
  ChallengePhase,
} from '@proftit/crm.api.models.entities';
import BrandChallengesGroupsService from '~/source/management/challenges/services/brand-challenges-groups.service';
import {
  demoAssetsList,
  parseChallengesResponse,
} from '~/source/management/challenges/components/challenge-editor/challenges-editor-uils';

export class ChallengePreviewPopupController {
  styles = styles;
  close: () => void;
  resolve: {
    brandId: number;
    challengeGroupId: number;
  };

  challenge: Challenge;
  challengeGroup: ChallengeGroup;
  accountBalances: ChallengeAccountBalanceConfig[][];
  phases: ChallengePhase[];

  /* @ngInject */
  constructor(
    readonly brandChallengesGroupsService: BrandChallengesGroupsService,
  ) {}

  async $onInit() {
    this.challengeGroup = await this.fetchChallenge();
    this.accountBalances = parseChallengesResponse(
      this.challengeGroup.challenges as Challenge[],
      'update',
    );
    this.phases = this.challengeGroup.challenges[0].phases as ChallengePhase[];
  }

  $onDestroy() {}

  $onChanges() {}

  phaseAssets(phaseAssets: number[]): string[] {
    return phaseAssets.map((id) => {
      const asset = demoAssetsList.find((asset) => asset.value === id);
      return asset ? asset.name : '';
    });
  }

  async fetchChallenge(): Promise<ChallengeGroup> {
    try {
      const data = await this.brandChallengesGroupsService.getChallengesGroupById(
        this.resolve.brandId,
        this.resolve.challengeGroupId,
      );
      return data.plain() as ChallengeGroup;
    } catch (error) {
      console.error(error);
    }
  }
}

export const ChallengePreviewPopupComponent = {
  controller: ChallengePreviewPopupController,
  template,
  bindings: {
    close: '&',
    resolve: '<',
  },
};
