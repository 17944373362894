import * as rx from '@proftit/rxjs';
import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import {
  Challenge,
  Customer,
  TradingAccount,
} from '@proftit/crm.api.models.entities';
import CustomersService from '~/source/contact/common/services/customers';
import template from './challenge-account-container.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import { da } from 'date-fns/locale';

const styles = require('./challenge-account-container.scss');

export class ChallengeAccountContainerController {
  styles = styles;
  lifecycles = observeComponentLifecycles(this);
  customer: Customer;
  challenges$ = this.streamCustomerChallenges();
  activeChallengeId: number;
  customerId: number;

  /*@ngInject*/
  constructor(readonly customersService: () => CustomersService) {
    useStreams([this.challenges$], this.lifecycles.onDestroy$);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  streamCustomerChallenges(): rx.Observable<Challenge[]> {
    return rx.pipe(
      () => this.lifecycles.onChanges$,
      rx.map(() => this.customer as Customer),
      rx.switchMap((customer: Customer) => {
        if (this.customerId !== customer.id) {
          return this.fetchCustomerChallenges(customer.id);
        }
      }),
      rx.tap((data) => {
        if (data && data.length > 0) {
          this.activeChallengeId = data[0].id;
        }
      }),
      shareReplayRefOne(),
    )(null);
  }

  async fetchCustomerChallenges(id: number): Promise<any[]> {
    const data = await this.customersService().getCustomerChallenges(id);
    return data.plain();
  }
}

export const ChallengeAccountContainerComponent = {
  template,
  controller: ChallengeAccountContainerController,
  bindings: {
    customer: '<',
  },
};
