import { observeComponentLifecycles } from '@proftit/rxjs.adjunct.ng1';
import { StateService, StateParams } from '@uirouter/core';
import BrandsService from '~/source/management/brand/services/brands';

import template from './challenge-currency-balance.component.html';
import {
  CHALLENGE_TYPES,
  ChallengeAccountBalanceConfig,
  ChallengeTypes,
} from '@proftit/crm.api.models.entities/src';
import { Currency } from '@proftit/crm.api.models.entities';

const styles = require('./challenge-currency-balance.component.scss');

export class ChallengeCurrencyBalanceController {
  styles = styles;
  model: ChallengeAccountBalanceConfig[][] = [];
  currencies: Currency[] = [];
  showFee: boolean;
  onUpdate: () => void;

  /*@ngInject */
  constructor(
    readonly brandsService: () => BrandsService,
    readonly $state: StateService,
    readonly $stateParams: StateParams,
  ) {}

  lifecycles = observeComponentLifecycles(this);

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  addBalanceAndFee() {
    let acc = this.currencies.map((c) => {
      return {
        currency: c,
        accountBalance: null,
        entryFee: null,
      };
    }) as ChallengeAccountBalanceConfig[];
    this.model.push(acc);
  }
  removeBalanceAndFee(index: number) {
    this.model.splice(index, 1);
  }
}

export const ChallengeCurrencyBalanceComponent = {
  controller: ChallengeCurrencyBalanceController,
  template: template,
  bindings: {
    model: '<',
    showFee: '<',
    currencies: '<',
    onUpdate: '&',
  },
};
