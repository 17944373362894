import {
  Challenge,
  CHALLENGE_TYPES,
  ChallengeAccountBalanceConfig,
  ChallengeAsset,
  ChallengeBalanceGroup,
  ChallengeBalanceGroupsCreate,
  ChallengeGroup,
  ChallengePhase,
  ChallengePhasePayload,
  ChallengePhaseType,
  ChallengeTypes,
} from '@proftit/crm.api.models.entities/src';
import * as _ from '@proftit/lodash';
import { isArray } from 'angular';
import { Currency } from '@proftit/crm.api.models.entities';

/**
 */

export type PageType = 'create' | 'update' | 'copy';
export type ActionRecord = Record<string, PageType>;
export enum PageTypes {
  CREATE = 'create',
  UPDATE = 'update',
  COPY = 'copy',
}
const generateFundedPhaseModel = (): ChallengePhase => ({
  name: 'Funded  Phase',
  description: 'This is the first phase.',
  isFunded: true,
  isActive: true,
  order: 1,
  fields: {
    tradingPeriod: {
      isActive: false,
      value: 10,
      periodsUnits: 'days',
    },
    minTradingPeriod: {
      isActive: false,
      value: 10,
      periodsUnits: 'days',
    },
    maxLoss: {
      isActive: true,
      value: 10,
      calculationType: 'percentage',
      calculationBasis: 'equity',
    },
    maxDailyLoss: {
      isActive: true,
      value: 10,
      calculationType: 'fixed',
      calculationBasis: 'equity',
    },
    profitTarget: {
      isActive: true,
      value: 10,
      calculationType: 'percentage',
      calculationBasis: 'balance',
    },
    earning: {
      value: 10,
      calculationType: 'percentage',
    },
  },
});

/**
 */
const generateRegularPhaseModel = (): ChallengePhase => ({
  id: 1,
  challengeId: 1,
  name: 'Phase 1 - Challenge 3',
  description: 'This is the first phase.',
  isFunded: false,
  isActive: false,
  order: 1,
  fields: {
    tradingPeriod: {
      isActive: true,
      value: 10,
      periodsUnits: 'days',
    },
    minTradingPeriod: {
      isActive: true,
      value: 5,
      periodsUnits: 'days',
    },
    maxLoss: {
      isActive: true,
      value: 10,
      calculationType: 'percentage',
      calculationBasis: 'equity',
    },
    maxDailyLoss: {
      isActive: true,
      value: 10,
      calculationType: 'percentage',
      calculationBasis: 'equity',
    },
    profitTarget: {
      isActive: true,
      value: 10,
      calculationType: 'percentage',
      calculationBasis: 'balance',
    },
    assets: [],
  },
});

/**
 * Generates a phase model based on the phase type.
 */
export const generatePhaseModel = (
  phaseType: ChallengePhaseType,
): ChallengePhase => {
  switch (phaseType) {
    case 'funded':
      return generateFundedPhaseModel();
    case 'regular':
      return generateRegularPhaseModel();
  }
};

/**
 * Sanitizes challenge balance groups by separating them into groups with and without IDs.
 */
export const sanitizeChallengeBalanceGroup = (
  balanceGroups: ChallengeAccountBalanceConfig[][],
) => {
  const groupWithId: ChallengeAccountBalanceConfig[][] = balanceGroups.filter(
    (group) => {
      return group.some((balance) => balance.id);
    },
  );

  const groupWithoutId: ChallengeAccountBalanceConfig[][] = balanceGroups.filter(
    (group) => {
      return !group.some((balance) => balance.id);
    },
  );

  return {
    groupWithId,
    groupWithoutId,
  };
};

/**
 * Prepares balance groups by sanitizing the input groups and creating new groups if necessary.
 */
export const prepareBalanceGroups = async (
  fetchFunction: (
    challengeGroupId: number,
    data: ChallengeBalanceGroupsCreate[],
  ) => Promise<ChallengeBalanceGroup[]>,
  group: ChallengeGroup,
  balanceGroups: ChallengeAccountBalanceConfig[][],
) => {
  const challengeGroupId = group.id;
  const { groupWithId, groupWithoutId } = sanitizeChallengeBalanceGroup(
    balanceGroups,
  );

  // Prepare data for new balance groups without IDs
  const data = groupWithoutId.map((group) => ({
    name: `Balance Group for group ${challengeGroupId} and balance ${group[0].accountBalance}`,
  }));

  // Fetch new balance groups
  const newGroups = await fetchFunction(challengeGroupId, data);

  // Update groups without IDs with the new group IDs
  const updatedGroups = groupWithoutId.map((group, index) =>
    group.map((g) => ({ ...g, id: newGroups[index].id })),
  );

  // Return the combined array of updated groups and groups with IDs
  return [..._.flatten(updatedGroups), ..._.flatten(groupWithId)].map((g) => ({
    ...g,
    challengeGroupId,
  })) as ChallengeAccountBalanceConfig[];
};
/**
 * Prepares challenge containers by matching challenges with their corresponding balance groups.
 */
export const prepareChallengesContainers = (
  challenges: Challenge[],
  balanceGroups: ChallengeAccountBalanceConfig[],
  challengeType: ChallengeTypes,
) => {
  // Map each balance group to a challenge, or create a new challenge if none exists.
  return balanceGroups.map((group) => {
    const challenge = challenges.find(
      (ch) =>
        ch.challengeBalanceGroupId === group.id &&
        group.currency.id === (ch.currency ? ch.currency.id : ch.currencyId) &&
        ch.balanceAmount === group.accountBalance &&
        ch.entryFee === group.entryFee,
    );
    if (challenge) {
      challenge.currencyId = group.currency.id;
    }
    // Return the found challenge or create a new one with the balance group's details.

    const ret = challenge || {
      challengeGroupId: group.challengeGroupId,
      challengeBalanceGroupId: group.id,
      currencyId: group.currency.id,
      name: `Challenge for ${group.accountBalance} ${group.currency.name}`,
      balanceAmount: group.accountBalance,
      entryFee: group.entryFee,
      phases: [],
    };
    if (challengeType === CHALLENGE_TYPES.TRIAL) {
      delete ret.entryFee;
    }
    return ret;
  });
};

export const challengePhasesCleanUp = (
  phases: ChallengePhase[],
): ChallengePhasePayload[] => {
  return phases.map((phase) => {
    const fields = phase.fields;
    const newFields = {
      ...fields,
      tradingPeriod: fields.tradingPeriod.value ?? null,
      minTradingPeriod: fields.minTradingPeriod.value ?? null,
      assets: isArray(fields.assets)
        ? fields.assets.map((asset: ChallengeAsset) => asset.value)
        : [],
    };
    delete newFields.earning;
    if (phase.isFunded) {
      newFields['profitSplit'] = fields.earning;
    }

    Object.keys(newFields).forEach((key) => {
      if (
        newFields[key] === null ||
        newFields[key] === undefined ||
        newFields[key].value === null
      ) {
        delete newFields[key];
      }
    });

    return {
      ...phase,
      fields: newFields,
    };
  });
};

/**
 * Prepares challenge phases by matching phases with their corresponding challenges.
 */

export const prepareChallengePhases = (
  challenges: Challenge[],
  phases: ChallengePhase[],
) => {
  return challenges.map((challenge) => {
    const _phases = challengePhasesCleanUp(phases);
    return {
      ...challenge,
      phases: _phases,
    };
  });
};

export const parsePhases = (
  phases: ChallengePhasePayload[],
): ChallengePhase[] => {
  return phases.map((phase) => {
    phase.fields =
      typeof phase.fields === 'string'
        ? JSON.parse(phase.fields)
        : phase.fields;
    let earning = null;
    if (phase.isFunded) {
      const value = phase.fields.profitSplit.value || 10;
      earning = {
        value,
        calculationType: phase.fields.profitSplit.calculationType,
      };
    }

    return {
      ...phase,
      fields: {
        tradingPeriod: {
          isActive: phase.fields.tradingPeriod ? true : false,
          value: phase.fields.tradingPeriod,
          periodsUnits: 'days',
        },
        minTradingPeriod: {
          isActive: phase.fields.minTradingPeriod ? true : false,
          value: phase.fields.minTradingPeriod,
          periodsUnits: 'days',
        },
        maxLoss: {
          isActive: phase.fields.maxLoss ? true : false,
          value: phase.fields.maxLoss ? phase.fields.maxLoss.value : null,
          calculationType: phase.fields.maxLoss
            ? phase.fields.maxLoss.calculationType
            : 'percentage',
          calculationBasis: phase.fields.maxLoss
            ? phase.fields.maxLoss.calculationBasis
            : 'equity',
        },
        maxDailyLoss: {
          isActive: true,
          value: phase.fields.maxDailyLoss
            ? phase.fields.maxDailyLoss.value
            : null,
          calculationType: phase.fields.maxDailyLoss
            ? phase.fields.maxDailyLoss.calculationType
            : 'percentage',
          calculationBasis: phase.fields.maxDailyLoss
            ? phase.fields.maxDailyLoss.calculationBasis
            : 'equity',
        },
        profitTarget: {
          isActive: phase.fields.profitTarget ? true : false,
          value: phase.fields.profitTarget
            ? phase.fields.profitTarget.value
            : null,
          calculationType: phase.fields.profitTarget
            ? phase.fields.profitTarget.calculationType
            : 'fixed',
          calculationBasis: phase.fields.profitTarget
            ? phase.fields.profitTarget.calculationBasis
            : 'balance',
        },
        earning: phase.isFunded ? earning : null,
        assets: phase.fields.assets.map((asset) => ({
          value: asset,
          label: 'Apple',
          name: 'APL',
        })),
      },
    };
  });
};

export const parseChallengesResponse = (
  challenges: Challenge[],
  pageActionType: PageType,
) => {
  const balanceGroupsGroupedByGroupId: Record<number, Challenge[]> = _.groupBy(
    (ch: Challenge) => ch.challengeBalanceGroupId,
    challenges,
  );

  const challengeBalanceGroup: ChallengeAccountBalanceConfig[][] = Object.values(
    balanceGroupsGroupedByGroupId,
  ).map((chGroup) => {
    return chGroup.map((challenge) => {
      return {
        id:
          pageActionType !== PageTypes.COPY
            ? challenge.challengeBalanceGroupId
            : null,
        accountBalance: challenge.balanceAmount,
        currency: challenge.currency as Currency,
        challengeGroupId: challenge.challengeGroupId,
        entryFee: challenge.entryFee,
      };
    });
  });
  return challengeBalanceGroup;
};
export const demoAssetsList: ChallengeAsset[] = [
  { value: 4, label: 'Apple', name: 'APL' },
  { value: 5, label: 'Google', name: 'GGL' },
  { value: 6, label: 'Microsoft', name: 'MST' },
  { value: 7, label: 'Facebook', name: 'FB' },
  { value: 8, label: 'Tesla', name: 'TSL' },
];
