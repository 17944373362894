import angular from 'angular';
import { ChallengeAccountContainerComponent } from '~/source/contact/contact-page/prop-account/challenge-account-container/challenge-account-container.component';
import { ChallengeAccountInfoComponent } from '~/source/contact/contact-page/prop-account/challenge-account-info/challenge-account-info.component';
import ChallengePurchaseTableComponent from '~/source/contact/contact-page/prop-account/challenge-purchase-table/challenge-purchase-table.component';
import ConfirmChallengePurchasePopupController from '~/source/contact/contact-page/prop-account/confirm-challenge-purchase-popup/confirm-challenge-purchase-popup.controller';
import { ChallengePhaseContainerComponent } from '~/source/contact/contact-page/prop-account/challenge-phase-container/challenge-phase-container.component';
import TradingAccountPropComponent from '~/source/contact/contact-page/prop-account/trading-account-prop/trading-account-prop.component';

export const ChallengeAccountModule = angular
  .module('crm.contact.challengeAccount', [])
  .component('prfChallengeAccountContainer', ChallengeAccountContainerComponent)
  .component('prfChallengePurchaseTable', ChallengePurchaseTableComponent)
  .component('prfChallengePhaseContainer', ChallengePhaseContainerComponent)
  .component(
    'prfConfirmChallengePurchasePopup',
    ConfirmChallengePurchasePopupController as any,
  )
  .component('prfChallengeAccountInfo', ChallengeAccountInfoComponent)
  .component('prfTradingAccountProp', TradingAccountPropComponent).name;
