import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './challenges-list.component.html';
import { ChallengesListTypes } from './challenges-list-types';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import ModalService from '~/source/common/components/modal/modal.service';
import { reorderDropdownItems } from '~/source/management/crypto-ewallet/helper-functions/reorder-items';
import { Brand } from '@proftit/crm.api.models.entities';
import BrandsService from '~/source/management/brand/services/brands';

import {
  CHALLENGE_TYPES,
  ChallengeGroup,
} from '@proftit/crm.api.models.entities/src';
import BrandChallengesGroupsService from '~/source/management/challenges/services/brand-challenges-groups.service';
import ChallengeGroupService from '~/source/management/challenges/services/challenge-group.service';
import PopupService from '~/source/common/components/modal/popup.service';

const styles = require('./challenges-list.component.scss');
type ChallengeGroupStatusAction = { group: ChallengeGroup; isActive: boolean };

export class ChallengesListController {
  styles = styles;

  lifecycles = observeComponentLifecycles(this);

  brand: Brand;
  brand$ = observeShareCompChange(this.lifecycles.onChanges$, 'brand');
  reload: () => void;
  ChallengesListTypes = ChallengesListTypes;
  challengeTypes = CHALLENGE_TYPES;
  reloadChallengesAction: rx.Subject<void>;
  onFormItemDroppedInActiveListAction = new rx.Subject<any>();
  onFormItemDroppedInDisabledListAction = new rx.Subject<any>();
  disableChallengeAction = new rx.Subject<ChallengeGroup>();
  setChallengeGroupStatusAction = new rx.Subject<ChallengeGroupStatusAction>();
  challengesProp$ = observeShareCompChange(
    this.lifecycles.onChanges$,
    'challenges',
  );
  challenges$ = this.streamChallenges();
  activeChallenges$ = this.streamActiveChallenges();
  disabledChallenges$ = this.streamDisabledChallenges();

  /* @ngInject */
  constructor(
    readonly modalService: ModalService,
    readonly brandsService: () => BrandsService,
    readonly brandChallengesGroupsService: BrandChallengesGroupsService,
    readonly challengeGroupService: ChallengeGroupService,
    readonly popupService: PopupService,
  ) {
    useStreams(
      [
        this.brand$,
        this.challenges$,
        this.activeChallenges$,
        this.disabledChallenges$,
        this.streamOpenActivateChallengeApprovalDialog(),
        this.streamOpenDeactivateChallengeApprovalDialog(),
        this.streamDisableChallenge(),
        this.streamSetChallengeGroupStatus(),
      ],
      this.lifecycles.onDestroy$,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  /**
   * Streams the challenges and sorts them by their order.
   *
   * @returns {rx.Observable<any>} An observable that emits the sorted challenges.
   */
  streamChallenges(): rx.Observable<any> {
    return rx.pipe(
      () => rx.obs.merge(this.challengesProp$),
      rx.map((challenges) =>
        _.sortBy((challenge) => challenge.order, challenges as any),
      ),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the active challenges and sorts them by their order.
   *
   * @returns {rx.Observable<any>} An observable that emits the sorted active challenges.
   */
  streamActiveChallenges() {
    return rx.pipe(
      () => this.challenges$,
      rx.filter((challenges) => !_.isNil(challenges)),
      rx.map((challenges) => challenges.filter((e) => e.isActive === true)),
      rx.map((activeChallenges) =>
        _.sortBy((challenge) => challenge.order, activeChallenges),
      ),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the disabled challenges and sorts them by their order.
   *
   * @returns {rx.Observable<any>} An observable that emits the sorted disabled challenges.
   */
  streamDisabledChallenges() {
    return rx.pipe(
      () => this.challenges$,
      rx.filter((challenges) => !_.isNil(challenges)),
      rx.map((challenges) => challenges.filter((e) => e.isActive === false)),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the action to open the activate challenge approval dialog.
   *
   * @returns {rx.Observable<any>} An observable that emits when the activate challenge approval dialog is opened.
   */
  streamOpenActivateChallengeApprovalDialog() {
    return rx.pipe(
      () => this.onFormItemDroppedInActiveListAction,
      rx.tap((data) => {
        const group = data.item as ChallengeGroup;
        return (this.openApprovalDialog(group, {
          msg: 'challenges.ACTIVATE_CHALLENGE_WARNING',
          name: group.name,
        })
          .result.then(() => {
            this.setChallengeGroupStatusAction.next({
              group: group,
              isActive: true,
            });
          })
          .catch(() => {
            return {
              modalConfirmed: false,
              data: null,
            };
          }) as any) as Promise<{ modalConfirmed: boolean; data: any }>;
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the action to open the deactivate challenge approval dialog.
   *
   * @returns {rx.Observable<any>} An observable that emits when the deactivate challenge approval dialog is opened.
   */
  streamOpenDeactivateChallengeApprovalDialog() {
    return rx.pipe(
      () => this.onFormItemDroppedInDisabledListAction,
      rx.tap((data) => {
        const group = data.item as ChallengeGroup;
        return (this.openApprovalDialog(group, {
          msg: 'challenges.DEACTIVATE_CHALLENGE_WARNING',
          name: group.name,
        })
          .result.then(() => {
            this.setChallengeGroupStatusAction.next({
              group,
              isActive: false,
            });
          })
          .catch(() => {
            return {
              modalConfirmed: false,
              data: null,
            };
          }) as any) as Promise<{ modalConfirmed: boolean; data: any }>;
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the action to disable a challenge.
   *
   * @returns {rx.Observable<any>} An observable that emits when a challenge is disabled.
   */
  streamDisableChallenge() {
    return rx.pipe(
      () => this.disableChallengeAction,
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Streams the action to set the status of a challenge group.
   *
   * @returns {rx.Observable<any>} An observable that emits when the status of a challenge group is set.
   */
  streamSetChallengeGroupStatus() {
    return rx.pipe(
      () => this.setChallengeGroupStatusAction,
      rx.switchMap((action: ChallengeGroupStatusAction) => {
        const { group, isActive } = action;
        return rx.obs
          .from(
            this.updateChallengeGroupStatus(
              group.brandId,
              group,
              isActive,
            ) as any,
          )
          .pipe(rx.catchError(() => rx.obs.NEVER));
      }),
      rx.tap(() => {
        this.reload();
      }),
      shareReplayRefOne(),
    )(null);
  }

  /**
   * Opens an approval dialog for activating or deactivating a challenge.
   *
   * @param {any} data - The data to be passed to the dialog.
   * @param {any} message - The message to be displayed in the dialog.
   * @returns {any} The result of the dialog.
   */
  openApprovalDialog(data, message) {
    return this.modalService.open({
      component: 'prfChallengeActivationApproval',
      resolve: {
        data,
        message: () => message,
      },
    });
  }

  /**
   * Updates the status of a challenge group.
   *
   * @param {number} brandId - The ID of the brand.
   * @param {ChallengeGroup} challengeGroup - The challenge group to be updated.
   * @param {boolean} isActive - The new status of the challenge group.
   * @returns {Promise<void>} A promise that resolves when the status is updated.
   */
  async updateChallengeGroupStatus(
    brandId: number,
    challengeGroup: ChallengeGroup,
    isActive: boolean,
  ) {
    await this.brandChallengesGroupsService.updateChallengeGroup(
      brandId,
      challengeGroup.id,
      {
        ...challengeGroup,
        isActive,
      },
    );
  }

  /**
   * Opens the challenge preview popup.
   *
   */
  openChallengePreviewPopup(id: number) {
    this.popupService.open({
      component: 'prfChallengePreviewPopup',

      resolve: {
        challengeGroupId: id,
        brandId: this.brand.id,
      },
    });
  }
}

export const ChallengesListComponent = {
  template,
  controller: ChallengesListController,
  bindings: {
    brand: '<',
    challenges: '<',
    reload: '&',
  },
};
