import template from './transaction-table-note.component.html';
const styles = require('./transaction-table-note.component.scss');

import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import { FormControl } from '@proftit/ng1.reactive-forms';
import { useStreams } from '@proftit/rxjs.adjunct';
import { CustomersService } from '~/source/contact/common/services/customers';
import { generateBlockuiId } from '~/source/common/utilities/generate-blockui-id';

export class TransactionTableNoteController {
  note: string;
  saveAction: (a: { note: string }) => Promise<any>;

  styles = styles;
  blockUiId = generateBlockuiId();
  lifecycles = observeComponentLifecycles(this);
  isEdit$ = new rx.BehaviorSubject<boolean>(false);
  note$ = observeShareCompChange<string>(this.lifecycles.onChanges$, 'note');
  // @ts-ignore
  localNote = new FormControl(this.note ? String(this.note) : '');
  closeAction = new rx.Subject<void>();
  clearInputAction = new rx.Subject<void>();
  pageType$ = new rx.Subject<void>();
  customersServiceInst: CustomersService;

  /*@ngInject */
  constructor(readonly customersService: () => CustomersService) {
    useStreams(
      [
        this.localNote.value$,
        this.streamSaveAction(),
        this.streamCloseAction(),
        this.streamClearInputAction(),
        this.streamLocalNote(),
      ],
      this.lifecycles.onDestroy$,
    );
    this.customersServiceInst = customersService();
  }

  streamLocalNote() {
    return rx.pipe(
      () => this.isEdit$,
      rx.withLatestFrom(this.note$),
      rx.tap(([isEdit, note]) => {
        this.localNote.setValue(note ? String(note) : '');
      }),
    )(null);
  }

  streamSaveAction() {
    return rx.pipe(
      () => this.pageType$,
      rx.withLatestFrom(this.localNote.value$, this.note$),
      rx.filter(([a, localNote, note]) => localNote !== String(note)),
      rx.switchMap(([a, localNote, note]) =>
        this.saveAction({ note: localNote as any }),
      ),
      rx.tap(() => this.closeAction.next()),
    )(null);
  }

  streamCloseAction() {
    return rx.pipe(
      () => this.closeAction,
      rx.tap(() => this.isEdit$.next(false)),
    )(null);
  }

  streamClearInputAction() {
    return rx.pipe(
      () => this.clearInputAction,
      rx.tap(() => this.localNote.setValue('')),
    )(null);
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}

  stopPropagation(event: UIEvent) {
    event.stopPropagation();
  }
}

export const TransactionTableNoteComponent = {
  template,
  controller: TransactionTableNoteController,
  bindings: {
    note: '<',
    saveAction: '&',
  },
};
