import BaseController from '~/source/common/controllers/base';
import template from './challenge-loss-profit.component.html';
import {
  CalculationBase,
  CalculationMethod,
  ChallengePhaseCalculationField,
} from '@proftit/crm.api.models.entities/src';
import { IScope } from 'angular';

const styles = require('./challenge-loss-profit.component.scss');

type CalculationBaseOption = {
  value: CalculationBase;
  label: string;
};

type CalculationMethodOption = {
  value: CalculationMethod;
  label: string;
};

class ChallengeLossController {
  styles = styles;
  onUpdate: () => void;
  model: ChallengePhaseCalculationField;
  mandatory: boolean;
  minValue: number | null;

  calculationBaseOptions: CalculationBaseOption[] = [
    {
      value: 'balance',
      label: 'Balance',
    },
    {
      value: 'equity',
      label: 'Equity',
    },
  ];

  calculationMethodOptions: CalculationMethodOption[] = [
    {
      value: 'percentage',
      label: 'Percentage',
    },
    {
      value: 'fixed',
      label: 'Fixed',
    },
  ];

  controlLabel: string;

  constructor(readonly $scope: IScope) {}

  $onInit() {
    this.minValue = this.minValue || 1;
    this.$scope.$watch('$ctrl.model.isActive', this.onActiveChange.bind(this));
    this.$scope.$watch('$ctrl.model.value', this.onValueChange.bind(this));
  }

  $onDestroy() {}

  /**
   * Handles the change of the active state of the loss/profit calculation.
   * This method updates the model's value and triggers an update notification through the `onUpdate`
   * callback, indicating that the active state has changed.
   */
  onActiveChange() {
    if (!this.model.isActive) {
      this.model.value = null;
    } else {
      this.model.value = this.minValue;
    }
    this.onUpdate();
  }

  /**
   * Handles the change of the value of the loss/profit calculation.
   * This method updates the model's value and triggers an update notification through the `onUpdate`
   * callback, indicating that the value has changed.
   */
  onValueChange() {
    if (!this.model.isActive && this.model.value) {
      this.model.isActive = true;
    }
    if (this.model.value === null) {
      this.model.isActive = false;
    }
    this.onUpdate();
  }

  /**
   * Handles the selection of a calculation base for the challenge's loss/profit calculation.
   * This method updates the model's calculation basis to the selected value and triggers an update
   * notification through the `onUpdate` callback, indicating that the calculation base has changed.
   *
   * @param {CalculationBase} calculationBase - The selected calculation base (e.g., 'balance' or 'equity').
   */
  onSelectCalculationBase(calculationBase: CalculationBase) {
    this.model.calculationBasis = calculationBase;
    this.onUpdate();
  }
}

export const ChallengeLossProfitComponent = {
  template,
  controller: ChallengeLossController,
  controllerAs: '$ctrl',
  bindings: {
    model: '=',
    label: '<',
    mandatory: '<',
    onUpdate: '&',
    minValue: '<',
  },
};
